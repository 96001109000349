import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';

type PolicyDisplayProps = {
  buttonName: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    policyDisplay: {
      WebkitTransform: `skew(-15deg)`,
      transform: `skew(-15deg)`,
      transition: `all 0.2s`,
      color: `#FFFFFA`,
      fontWeight: 600,
      boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
      borderRadius: 0,
      backgroundImage: `-webkit-gradient(linear, left top, right top, from(#38b48b), to(#00a381))`,
      fontSize: `1rem`,
      padding: theme.spacing(2),
      width: `100%`,
      textAlign: `center`,
    },
  }),
);

const PolicyDisplay: React.VFC<PolicyDisplayProps> = ({ buttonName }) => {
  const classes = useStyles();

  return (
    <div className={classes.policyDisplay}>
      <span>{buttonName}</span>
    </div>
  );
};

export default PolicyDisplay;
