import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import LinkButton from '../basic/LinkButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: `calc(1rem + 2vw) auto`,
      position: `relative`,
    },
    sectionTitle: {
      fontWeight: 900,
      fontSize: `2.4em`,
      '& :first-child': {
        textIndent: `1.5rem`,
      },
      [theme.breakpoints.only(`xs`)]: {
        textIndent: `0.5rem`,
      },
    },
    sectionText: {
      fontWeight: 600,
      fontSize: `1.2em`,
      lineHeight: 3,
      margin: `1rem auto`,
      [theme.breakpoints.only(`xs`)]: {
        lineHeight: 2,
        margin: `1rem auto .5rem`,
      },
    },
    buttonWrapper: {
      textAlign: `center`,
    },
    backgroundText: {
      position: `absolute`,
      fontWeight: 900,
      fontSize: `3.6em`,
      color: `#EFEFEF`,
      left: `0`,
      top: `0`,
      zIndex: -1,
      [theme.breakpoints.only(`xs`)]: {
        fontSize: `3em`,
      },
    },
    brAdjust: {
      display: `inline-block`,
    },
  }),
);

const SimpleMessage: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <Typography variant="h2" align="center" className={classes.sectionTitle}>
        <span className={classes.brAdjust}>伝承。</span>
        <span className={classes.brAdjust}>そして新たな飛躍へ。</span>
      </Typography>
      <div>
        <Typography className={classes.sectionText} align="center">
          <span className={classes.brAdjust}>「失われた３０年」を超えて、</span>
          <span className={classes.brAdjust}>新たな飛躍を遂げるため。</span>
          <br />
          <span className={classes.brAdjust}>藤川たかしと日本維新の会は、</span>
          <span className={classes.brAdjust}>挑み続けます。</span>
        </Typography>
      </div>
      <div className={classes.buttonWrapper}>
        <LinkButton name="メッセージを読む" linkTo="message" />
      </div>
      <Typography className={classes.backgroundText} align="center">
        From Tradition
        <br />
        To The Next Leap.
      </Typography>
    </section>
  );
};

export default SimpleMessage;
