import React from 'react';
import { useAtom } from 'jotai';

import { contactSuccessState, contactErrorState } from '@/others/atom';

import SuccessAlert from '../middle/SuccessAlert';
import ErrorAlert from '../middle/ErrorAlert';

const Notice: React.VFC = () => {
  const successIsOpen = useAtom(contactSuccessState);
  const errorIsOpen = useAtom(contactErrorState);

  if (successIsOpen) {
    return <SuccessAlert />;
  }

  if (errorIsOpen) {
    return <ErrorAlert />;
  }

  return <div />;
};

export default Notice;
