import React from 'react';
import { PageProps } from 'gatsby';

import Container from '@material-ui/core/Container';

import Notice from '@/components/Top/section/Notice';
import FormBackDrop from '@/components/Top/middle/FormBackDrop';
import Layout from '@/components/others/Layout';
import FirstView from '@/components/Top/section/FirstView';
import StaticSEO from '@/components/common/section/StaticSEO';
import SimpleProfile from '@/components/Top/section/SimpleProfile';
import SimplePolicies from '@/components/Top/section/SimplePolicies';
import Message from '@/components/Top/section/Message';
import Contact from '@/components/Top/section/Contact';
import Appeal from '@/components/Top/section/Appeal';
import SimpleMessage from '@/components/Top/section/SimpleMessage';

const Home: React.VFC<PageProps> = () => (
  <Layout>
    <StaticSEO
      top
      OgImageData="FirstViewSM"
      titleData="藤川たかし 公式HP | トップ"
    />
    <Notice />
    <FormBackDrop />
    <FirstView />
    <Container maxWidth="md">
      <SimpleMessage />
      <SimpleProfile />
      <SimplePolicies />
      <Appeal />
      <Contact />
    </Container>
  </Layout>
);

export default Home;
