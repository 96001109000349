import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import SectionTitle from '@/components/common/basic/SectionTitle';
import LinkButton from '../basic/LinkButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      margin: `calc(1rem + 2vw) auto`,
    },
    profileBox: {
      margin: `1.2rem auto`,
      borderRadius: 0,
    },
    cardContent: {
      padding: theme.spacing(3),
      '&:last-child': {
        paddingBottom: theme.spacing(2),
      },
    },
    strong: {
      color: `#FF312E`,
      fontSize: `1.2em`,
    },
    buttonWrapper: {
      textAlign: `center`,
    },
  }),
);

const SimpleProfile: React.VFC = () => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <SectionTitle titleBg="Profile" title="プロフィール" />
      <div>
        <Typography>
          1956年9月3日生まれ、藤川たかしのプロフィールです。
        </Typography>
      </div>
      <Card className={classes.profileBox}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2">
            藤川たかしは、<span className={classes.strong}>学</span>び続けます
          </Typography>
          <Typography>
            神奈川県立湘南高等学校を卒業後、横浜国立大学工学部に進学、卒業後はそのまま大学院に進学しました。また、台湾の臺灣師範大学では中国語標準語、早稲田大学公共経営大学院では行政の仕組みと政治との関係と、幅広い分野を学んできました。
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.profileBox}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h2">
            藤川たかしは、<span className={classes.strong}>挑</span>み続けます
          </Typography>
          <Typography>
            横浜国立大学大学院工部を終了後は、三井物産株式会社へ就職。その後は4社の起業を経験しています。日本から中国まで幅広い範囲で活動してきました。2015年からは新宿区議会議員も勤め、新宿区政の改革に貢献しました。
          </Typography>
        </CardContent>
      </Card>
      <div className={classes.buttonWrapper}>
        <LinkButton name="詳しいプロフィールはこちら" linkTo="profile" />
      </div>
    </section>
  );
};

export default SimpleProfile;
