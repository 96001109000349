import React, { useState } from 'react';
import Axios from 'axios';
import { useAtom } from 'jotai';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {
  contactSuccessState,
  contactErrorState,
  backDropStatus,
} from '@/others/atom';
import FormKeys from '@/others/contactFormKeys';

import SectionTitle from '@/components/common/basic/SectionTitle';
import FormField from '../basic/FormField';

const useStyles = makeStyles({
  section: {
    margin: `calc(1rem + 2vw) auto`,
  },
  formButton: {
    margin: `1rem auto`,
    borderRadius: 0,
    fontWeight: `bold`,
  },
  formBox: {
    margin: `1.2rem auto`,
    borderRadius: 0,
  },
});

const Form: React.VFC = () => {
  const [email, setEmail] = useState(``);
  const [subject, setSubject] = useState(``);
  const [content, setContent] = useState(``);
  const [emailIsCorrect, setEmailIsCorrect] = useState(true);
  const [contentIsCorrect, setContentIsCorrect] = useState(true);
  const [, setSuccessIsOpen] = useAtom(contactSuccessState);
  const [, setErrorIsOpen] = useAtom(contactErrorState);
  const [, setBackDropStatus] = useAtom(backDropStatus);

  const handleSubmit = () => {
    setBackDropStatus(true);
    const submitParams = new FormData();

    submitParams.append(FormKeys.email, email);
    submitParams.append(FormKeys.subject, subject);
    submitParams.append(FormKeys.content, content);

    Axios.post(FormKeys.corsProxy + FormKeys.action, submitParams)
      .then((response) => {
        console.log(response);
        setEmail(``);
        setSubject(``);
        setContent(``);
        setErrorIsOpen(false);
        setSuccessIsOpen(true);
        setBackDropStatus(false);
      })
      .catch((error) => {
        console.log(error);
        setSuccessIsOpen(false);
        setErrorIsOpen(true);
        setBackDropStatus(false);
      });
  };

  const classes = useStyles();

  return (
    <section className={classes.section}>
      <SectionTitle titleBg="Contact" title="お問い合わせ" />
      <Card className={classes.formBox}>
        <CardContent>
          <form>
            <FormField
              name="email"
              label="メールアドレス（必須）"
              type="email"
              setFieldState={setEmail}
              isCorrect={emailIsCorrect}
              setIsCorrect={setEmailIsCorrect}
            />
            <FormField
              name="subject"
              label="件名"
              setFieldState={setSubject}
              isCorrect
            />
            <FormField
              name="content"
              label="お問い合わせ内容（必須）"
              setFieldState={setContent}
              isCorrect={contentIsCorrect}
              setIsCorrect={setContentIsCorrect}
              rows={6}
            />
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="primary"
              onClick={handleSubmit}
              className={classes.formButton}
              disabled={
                !(
                  email !== `` &&
                  emailIsCorrect &&
                  content !== `` &&
                  contentIsCorrect
                )
              }
            >
              送信
            </Button>
          </form>
        </CardContent>
      </Card>
    </section>
  );
};

export default Form;
