import React from 'react';
import { navigate } from 'gatsby';

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';

type LinkButtonProps = {
  name: string;
  linkTo: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toProfileButton: {
      borderRadius: 0,
      padding: theme.spacing(2),
      margin: `1rem auto`,
      fontWeight: 600,
      '&::before': {
        position: `absolute`,
        width: `100%`,
        height: `2px`,
        content: `""`,
        background: `#000103`,
        transition: `all .3s`,
        top: 0,
        left: 0,
      },
      '&::after': {
        position: `absolute`,
        width: `100%`,
        height: `2px`,
        content: `""`,
        background: `#000103`,
        transition: `all .5s`,
        right: 0,
        bottom: 0,
      },
      '&:hover': {
        textShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
        fontSize: `1.1em`,
        '&::before': {
          width: 0,
        },
        '&::after': {
          width: 0,
        },
      },
    },
  }),
);

const LinkButton: React.VFC<LinkButtonProps> = (props) => {
  const { name, linkTo } = props;
  const classes = useStyles();

  const handleClick = () => navigate(`/${linkTo}`);

  return (
    <ButtonBase className={classes.toProfileButton} onClick={handleClick}>
      <span>{name}</span>
    </ButtonBase>
  );
};

export default LinkButton;
