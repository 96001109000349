import React from 'react';

import Typograpy from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import SectionTitle from '@/components/common/basic/SectionTitle';
import PolicyDisplay from '../basic/PolicyDisplay';
import LinkButton from '../basic/LinkButton';

const useStyles = makeStyles({
  section: {
    margin: `calc(1rem + 2vw) auto`,
  },
  buttonWrapper: {
    margin: `1.5rem auto`,
    textAlign: `center`,
  },
});

const SimplePolicies: React.VFC = () => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <SectionTitle titleBg="Policy" title="6つの政策" />
      <Typograpy>藤川たかしは、6つの政策で日本を改革します。</Typograpy>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="01. 日本大改革／新所得倍増計画" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="02. 防災都市" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="03. 教育の無償化／多様性の街" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="04. 外交・防衛" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="05. エネルギー政策" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PolicyDisplay buttonName="06. 憲法改正 " />
        </Grid>
      </Grid>
      <div className={classes.buttonWrapper}>
        <LinkButton name="詳しい政策はこちら" linkTo="policies" />
      </div>
    </section>
  );
};

export default SimplePolicies;
