import React from 'react';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  appealBox: {
    margin: `calc(3rem + 2vw) auto`,
  },
  appeal: {
    fontSize: `calc(12px + 1.5vw)`,
    fontWeight: 600,
    lineHeight: 2,
  },
  strong: {
    color: `#FF312E`,
    fontSize: `1.2em`,
  },
});

const Appeal: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.appealBox}>
      <Typography
        variant="h2"
        component="p"
        align="center"
        className={classes.appeal}
      >
        <span className={classes.strong}>今</span>、日本を
        <span className={classes.strong}>変</span>える。
        <br />
        藤川たかしへのご支援をお願いします。
      </Typography>
    </section>
  );
};

export default Appeal;
