import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    FirstViewImageBox: {
      width: `100%`,
      height: `100%`,
      [theme.breakpoints.down(750)]: {
        height: `88vw`,
      },
    },
    FirstViewImageStyle: {
      width: `100%`,
      height: `100%`,
    },
  }),
);

const FirstViewImage: React.VFC = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    {
      forPC: file(relativePath: { eq: "FirstViewLG.jpg" }) {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
      forSP: file(relativePath: { eq: "FirstViewXS.jpg" }) {
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const images = withArtDirection(
    getImage(data.forPC?.childImageSharp?.gatsbyImageData),
    [
      {
        media: `(max-width: 750px)`,
        image: getImage(data.forSP?.childImageSharp?.gatsbyImageData),
      },
    ],
  );

  return (
    <div className={classes.FirstViewImageBox}>
      <GatsbyImage
        image={images}
        alt="main image"
        className={classes.FirstViewImageStyle}
        objectPosition="50% 50%"
      />
    </div>
  );
};

export default FirstViewImage;
