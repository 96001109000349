import React from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core';

import FirstViewImage from '../basic/FirstViewImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    FirstViewStyle: {
      padding: `0 0 0`,
    },
    brAdjust: {
      display: `inline-block`,
    },
    firstViewBox: {
      position: `relative`,
    },
  }),
);

const FirstView: React.VFC = () => {
  const classes = useStyles();

  return (
    <section className={classes.FirstViewStyle}>
      <div className={classes.firstViewBox}>
        <FirstViewImage />
      </div>
    </section>
  );
};

export default FirstView;
