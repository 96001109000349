import React from 'react';
import { useAtom } from 'jotai';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { contactErrorState } from '@/others/atom';

const useStyles = makeStyles({
  errorAlert: {
    position: `fixed`,
    top: `2vh`,
    left: `50%`,
    transform: `translateX(-50%)`,
    width: `85%`,
    zIndex: 1100,
    display: `flex`,
    alignItems: `center`,
  },
  adjustAlertTitle: {
    marginBottom: 0,
  },
});

const ErrorAlert: React.VFC = () => {
  const [isOpen, setIsOpen] = useAtom(contactErrorState);
  const classes = useStyles();

  return (
    <div>
      <Collapse in={isOpen}>
        <Alert
          severity="error"
          className={classes.errorAlert}
          elevation={5}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle className={classes.adjustAlertTitle}>
            入力内容をご確認ください
          </AlertTitle>
        </Alert>
      </Collapse>
    </div>
  );
};

export default ErrorAlert;
